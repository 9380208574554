import { FC, Fragment, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { format, parseISO } from 'date-fns';
import { formatIncompletePhoneNumber } from 'libphonenumber-js/mobile';
import { Stack, Typography, Box, IconButton } from '@mui/material';
import { useTheme } from '@emotion/react';

import { phoneNumberPlus } from 'src/utils/phone-number-plus';
import { FlagIcon } from 'src/components/flag-icon';
import { Button } from 'src/components/button';
import { LogoutDialog } from 'src/features/auth';
import { ReactComponent as EditIcon } from 'src/assets/svg/pen.svg';
import { ReactComponent as LogoutIcon } from 'src/assets/svg/exit.svg';
import { GA4 } from 'src/lib/ga4';
import { LanguagesCode } from 'src/i18n/constants';

import { UserDataFields } from '../api';
import { useGetCurrentUser } from '../hooks/use-get-current-user';
import { useFeatureFlags } from '../hooks/use-feature-flags';
import { useIsCheckedIn } from '../../bookings';

import { ProfileAvatar } from './profile-avatar/profile-avatar';
import { UserDataRow } from './user-data-row';
import { UserSettings } from './user-settings/user-settings';
import { FeedbackAndReviewsCTA } from './user-settings/parts/feedback-and-reviews-cta';

export const ViewProfile: FC = () => {
    const navigate = useNavigate();

    const {
        t,
        i18n: { language },
    } = useTranslation();

    const featureFlags = useFeatureFlags();
    const isCheckedIn = useIsCheckedIn();

    const { data } = useGetCurrentUser();
    const user = data!;

    const { palette, breakpoints } = useTheme();
    const [showLogoutDialog, setShowLogoutDialog] = useState(false);

    const toggleLogoutDialog = useCallback(
        () => setShowLogoutDialog((state) => !state),
        [setShowLogoutDialog]
    );

    const goToEdit = () => {
        navigate('edit');

        GA4.ctaEvent({
            name: 'Edit Profile Click',
            label: 'Edit Icon',
            location: 'Edit Profile Form',
        });
    };

    const birthDate = user[UserDataFields.BirthDate];
    const formattedBirthDate = birthDate && format(parseISO(birthDate), 'dd/MM/yyyy');

    const userLanguage = user[UserDataFields.Language];
    const phoneNumber = user[UserDataFields.PhoneNumber];
    const emergencyContactPhone = user[UserDataFields.EmergencyContactPhone];

    const languages = new Intl.DisplayNames([language], { type: 'language' });

    const { phoneNumber: formattedPhoneNumber, countryCode } = phoneNumberPlus(phoneNumber || '');
    const { phoneNumber: emergencyFormattedPhoneNumber, countryCode: emergencyCountryCode } =
        phoneNumberPlus(emergencyContactPhone || '');

    const handleLogoutTracking = () =>
        GA4.ctaEvent({
            name: 'Logout',
            location: 'No Bookings Page',
            label: t('logout_CTA', { lng: LanguagesCode.English }),
        });

    return (
        <Fragment>
            <Box padding={{ xs: '16px 16px 0 16px', md: '60px 60px 0 60px' }} flex={1}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    marginBottom="54px"
                    display={{ md: 'none' }}
                >
                    <ProfileAvatar variant="vertical" />
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    marginBottom="32px"
                >
                    <Typography variant="h4">{t('user_details_title')}</Typography>
                    <IconButton onClick={goToEdit}>
                        <EditIcon color={palette.primary.main} />
                    </IconButton>
                </Stack>

                <Stack component="form" direction={{ lg: 'row' }} columnGap="120px">
                    <Box flex={1} borderBottom={{ xs: `1px solid ${palette.divider}`, lg: 'none' }}>
                        <UserDataRow
                            label={t('gender_label')}
                            content={user[UserDataFields.Gender]}
                        />
                        <UserDataRow label={t('dob_label')} content={formattedBirthDate} />
                        <UserDataRow
                            label={t('email_label')}
                            content={user[UserDataFields.Email]}
                        />
                        <UserDataRow
                            label={t('number_label')}
                            content={
                                <Stack direction="row" alignItems="center">
                                    <FlagIcon
                                        code={countryCode || ''}
                                        className="flag-icon"
                                        style={{ width: 24, height: 24, marginRight: '8px' }}
                                    />
                                    {formatIncompletePhoneNumber(formattedPhoneNumber)}
                                </Stack>
                            }
                        />
                        <UserDataRow
                            label={t('country_label')}
                            content={user[UserDataFields.AddressCountry]}
                        />
                        <UserDataRow
                            label={t('city_label')}
                            content={user[UserDataFields.AddressCity]}
                        />
                        <UserDataRow
                            label={t('postcode_label')}
                            content={user[UserDataFields.AddressPostalCode]}
                        />
                        <UserDataRow
                            label={t('address_label')}
                            content={user[UserDataFields.AddressStreet]}
                        />
                    </Box>

                    <Box flex={1}>
                        <UserDataRow
                            label={t('language_label')}
                            content={userLanguage && languages.of(userLanguage)}
                        />
                        <UserDataRow
                            label={t('nationality_label')}
                            content={user[UserDataFields.Nationality]}
                        />
                        <UserDataRow
                            label={t('university_label')}
                            content={user[UserDataFields.University]}
                        />
                        <UserDataRow
                            label={t('course_label')}
                            content={user[UserDataFields.Course]}
                        />
                        <UserDataRow
                            label={t('yos_label')}
                            content={user[UserDataFields.UniversityYear]}
                        />
                        <UserDataRow
                            label={t('emergency_name_label')}
                            content={user[UserDataFields.EmergencyContactName]}
                        />
                        <UserDataRow
                            label={t('emergency_number_label')}
                            content={
                                <Stack direction="row" alignItems="center">
                                    <FlagIcon
                                        code={emergencyCountryCode || ''}
                                        className="flag-icon"
                                        style={{ width: 24, height: 24, marginRight: '8px' }}
                                    />
                                    {formatIncompletePhoneNumber(emergencyFormattedPhoneNumber)}
                                </Stack>
                            }
                        />
                    </Box>
                </Stack>

                <UserSettings />

                <LogoutDialog
                    isOpen={showLogoutDialog}
                    onClose={toggleLogoutDialog}
                    onLogout={handleLogoutTracking}
                />
            </Box>

            {featureFlags.feedback && isCheckedIn && (
                <FeedbackAndReviewsCTA
                    css={{
                        margin: '24px 16px 0 16px',
                        [breakpoints.up('md')]: { margin: '60px 0 0 0' },
                    }}
                />
            )}

            <Button
                design="light"
                bordered
                startIcon={<LogoutIcon />}
                onClick={toggleLogoutDialog}
                css={{
                    margin: '60px 16px',
                    [breakpoints.up('md')]: {
                        display: 'none',
                    },
                }}
            >
                {t('logout_CTA')}
            </Button>
        </Fragment>
    );
};
