import { FC } from 'react';
import { IconButton, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import { ReactComponent as SuccessIcon } from 'src/assets/svg/success-in-circle.svg';
import { ReactComponent as WarningIcon } from 'src/assets/svg/warning.svg';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { Booking } from 'src/features/bookings';

export const AmendCard: FC<{ upcomingBooking: Booking }> = ({ upcomingBooking }) => {
    const { palette, transitions } = useTheme();

    const amendSigned = upcomingBooking.amend_primary_occupant_signed_date;

    const { t } = useTranslation();

    return (
        <Stack
            component={amendSigned ? 'div' : Link}
            {...(!amendSigned && { to: upcomingBooking.amend_url })}
            css={{
                padding: 0,
                width: '100%',
                borderRadius: 0,
                textDecoration: 'inherit',
                color: 'inherit',
                ':hover': {
                    '[aria-label="link title"]': {
                        ':after': {
                            width: '100%',
                        },
                    },
                },
            }}
        >
            <Stack
                padding="20px"
                gap="16px"
                width="100%"
                direction="row"
                css={{
                    borderBottom: `1px solid ${palette.divider}`,
                    color: palette.common.black,
                    position: 'relative',
                }}
            >
                {amendSigned ? (
                    <SuccessIcon
                        width="32px"
                        height="32px"
                        color={palette.success.main}
                        css={{ flexShrink: 0 }}
                    />
                ) : (
                    <WarningIcon
                        width="32px"
                        height="32px"
                        color={palette.error.main}
                        css={{ flexShrink: 0 }}
                    />
                )}

                <Stack flex={1} direction="column" gap="4px">
                    <Typography
                        variant="h4"
                        aria-label="link title"
                        width="fit-content"
                        css={{
                            paddingBottom: '4px',
                            position: 'relative',
                            ':after': {
                                content: '""',
                                display: 'block',
                                position: 'absolute',
                                height: '1px',
                                width: 0,
                                bottom: 0,
                                background: palette.text.primary,
                                transition: transitions.create('width'),
                            },
                        }}
                    >
                        {amendSigned ? t('booking_updated_title') : t('confirm_update_title')}
                    </Typography>
                    {amendSigned ? (
                        <Typography variant="body2" color={palette.text.secondary}>
                            {t('booking_updated_subtitle')}
                        </Typography>
                    ) : (
                        <Typography variant="body2" color={palette.text.secondary}>
                            {t('confirm_update_subtitle')}
                        </Typography>
                    )}
                </Stack>

                <IconButton css={{ alignSelf: 'center' }}>
                    {amendSigned ? (
                        <ArrowIcon
                            css={{
                                color: palette.text.disabled,
                                width: '24px',
                                height: '24px',
                                transform: 'rotate(-90deg)',
                            }}
                        />
                    ) : (
                        <ArrowIcon
                            css={{
                                color: palette.common.black,
                                width: '24px',
                                height: '24px',
                                transform: 'rotate(-90deg)',
                            }}
                        />
                    )}
                </IconButton>
            </Stack>
        </Stack>
    );
};
