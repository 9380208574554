import { useTheme } from '@emotion/react';
import { Box, Stack, Typography } from '@mui/material';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ASSETS_BASE_URL } from 'src/api/concierge';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { Image, SrcSet } from 'src/components/image';
import { routes } from 'src/constants/routes';

import { RebookStatus } from '../../api/constants';
import { useGetRebookingPhase } from '../../hooks/use-get-rebooking-room-phase';

interface Content {
    title: string;
    description: string;
    primary_message: string;
    secondary_message: string;
    image_srcset: SrcSet;
}

const CONTENT_MAP = new Map<RebookStatus, Content>([
    [
        RebookStatus.ComingSoon,
        {
            title: 'rebookers-card_coming-soon_subtitle',
            primary_message: 'rebookers-card_coming-soon_years',
            secondary_message: 'rebookers-card_coming-soon_title',
            description: 'rebookers-card_coming-soon_description',
            image_srcset: {
                xl: `${ASSETS_BASE_URL}/images/rebook_coming_soon_banner_1920.jpeg`,
                lg: `${ASSETS_BASE_URL}/images/rebook_coming_soon_banner_1366.jpeg`,
                md: `${ASSETS_BASE_URL}/images/rebook_coming_soon_banner_768.jpeg`,
                xs: `${ASSETS_BASE_URL}/images/rebook_coming_soon_banner_375.jpeg`,
            },
        },
    ],

    [
        RebookStatus.Active,
        {
            title: 'rebookers-card_coming-active_subtitle',
            primary_message: 'rebookers-card_coming-soon_years',
            secondary_message: 'rebookers-card_coming-soon_title',
            description: 'rebookers-card_coming-soon_description',
            image_srcset: {
                xl: `${ASSETS_BASE_URL}/images/rebook_coming_soon_banner_1920.jpeg`,
                lg: `${ASSETS_BASE_URL}/images/rebook_coming_soon_banner_1366.jpeg`,
                md: `${ASSETS_BASE_URL}/images/rebook_coming_soon_banner_768.jpeg`,
                xs: `${ASSETS_BASE_URL}/images/rebook_coming_soon_banner_375.jpeg`,
            },
        },
    ],

    [
        RebookStatus.Ended,
        {
            title: 'rebookers-card_campaign-ended_title',
            primary_message: 'rebookers-card_campaign-ended_message',
            secondary_message: 'rebookers-card_campaign-ended_years',
            description: 'rebookers-card_campaign-ended_description',
            image_srcset: {
                xl: `${ASSETS_BASE_URL}/images/rebooking-banner_1920px.png`,
                lg: `${ASSETS_BASE_URL}/images/rebooking-banner_1366px.png`,
                md: `${ASSETS_BASE_URL}/images/rebooking-banner_768px.png`,
                xs: `${ASSETS_BASE_URL}/images/rebooking-banner_375px.png`,
            },
        },
    ],
]);

export const RebookingBanner: FC<{ className?: string }> = ({ className }) => {
    const { palette } = useTheme();
    const { t } = useTranslation();

    const { data: phaseInfo, isLoading, isError } = useGetRebookingPhase();
    const rebookStatus = phaseInfo?.rebook_status;

    if (
        isError ||
        isLoading ||
        !phaseInfo.is_available ||
        !rebookStatus ||
        rebookStatus === RebookStatus.NotAllowed
    ) {
        return null;
    }

    const content = CONTENT_MAP.get(rebookStatus)!;

    const disableLink = false;
    const linkProps = disableLink
        ? {}
        : {
              component: Link,
              to: routes.rebooking.index,
          };

    const maximumDiscount = phaseInfo.phases.reduce((max, { active, discount }) => {
        if (active && discount && discount > max) {
            return discount;
        }

        return max;
    }, 0);

    return (
        <Box
            {...linkProps}
            css={{
                display: 'block',
                textDecoration: 'none',
                color: 'inherit',
                paddingBottom: '16px',
            }}
            className={className}
        >
            <Stack direction="row">
                <Box position="relative" flex={1} display={{ xs: 'none', md: 'inherit' }}>
                    <Image srcSet={content.image_srcset} alt="Rebookers banner" />

                    <Typography
                        position="absolute"
                        top="24px"
                        left="23px"
                        variant="h2"
                        color={palette.primary.contrastText}
                        textTransform="uppercase"
                    >
                        <Trans i18nKey={content.title} />
                    </Typography>
                </Box>

                <Box flex={1} css={{ background: '#F6E946' }} position="relative">
                    <Stack
                        rowGap="31px"
                        marginLeft={{ xs: '30px', lg: '40px' }}
                        marginTop={{ xs: '40px', lg: '40px' }}
                        marginBottom={{ xs: '68px', lg: '68px' }}
                        marginRight={{ xs: '73px', lg: '73px' }}
                    >
                        <Box display={{ xs: 'inherit', md: 'none' }}>
                            <Typography
                                position="absolute"
                                top="24px"
                                left="23px"
                                variant="h3"
                                textTransform="uppercase"
                            >
                                <Trans i18nKey={content.title} />
                            </Typography>
                        </Box>
                        <Box width="fit-content" textAlign="right">
                            <Typography variant="h1" color="black" textTransform="uppercase">
                                {t(content.secondary_message)}
                            </Typography>
                            <Typography variant="h3" color="black" textAlign="right" width="100%">
                                <Trans
                                    i18nKey={content.primary_message}
                                    values={{ discount: maximumDiscount }}
                                />
                            </Typography>
                        </Box>
                        <Typography variant="overlineMedium" color="black" fontSize="18px">
                            {t(content.description)}
                        </Typography>
                    </Stack>
                    <ArrowIcon
                        color={palette.common.black}
                        css={{
                            position: 'absolute',
                            right: 15,
                            bottom: 18,
                            transform: 'rotate(-90deg)',
                        }}
                    />
                </Box>
            </Stack>
        </Box>
    );
};
