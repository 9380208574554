import { ChangeEventHandler, FC, useEffect, useState } from 'react';
import {
    AsYouType,
    CountryCode,
    getCountries,
    getCountryCallingCode,
    validatePhoneNumberLength,
} from 'libphonenumber-js';
import {
    InputLabel,
    MenuItem,
    Select,
    SelectProps,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FlagIcon } from 'src/components/flag-icon';
import { CountryCodes, Language } from 'src/i18n/constants';
import { phoneNumberPlus } from 'src/utils/phone-number-plus';

import { PhoneNumber } from '../../../api';

const PHONE_COUNTRIES_MAP = getCountries();
const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

PHONE_COUNTRIES_MAP.sort((a, b) => {
    const aName = regionNames.of(a) as string;
    const bName = regionNames.of(b) as string;

    return aName.localeCompare(bName);
});

export const PhoneInput: FC<{
    value: PhoneNumber;
    error?: boolean;
    helperText?: string;
    label: string;
    required?: boolean;
    onChange: (phoneNumber: PhoneNumber) => void;
}> = ({ value, error, helperText, label, required, onChange }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const defaultCountryCode: CountryCode =
        currentLanguage === Language.EN ? CountryCodes.UK : CountryCodes.SPAIN;

    const [countryCode, setCountryCode] = useState<CountryCode>(
        value.countryCode || defaultCountryCode
    );

    useEffect(() => {
        if (value.number) {
            const { countryCode: newCountryCode } = phoneNumberPlus(value.number);
            if (newCountryCode && newCountryCode !== countryCode) {
                setCountryCode(newCountryCode as CountryCode);
            }
        }
    }, [defaultCountryCode, countryCode]);

    const callingCode = getCountryCallingCode(countryCode);

    const inputValue =
        value.number && !value.number.startsWith(callingCode)
            ? `+${callingCode}${value.number}`
            : value.number || `+${callingCode}`;

    const handleChangeCountry: SelectProps<CountryCode>['onChange'] = (event) => {
        const newCountryCode = event.target.value as CountryCode;
        const newCallingCode = getCountryCallingCode(newCountryCode);

        setCountryCode(newCountryCode);
        onChange({
            countryCode: newCountryCode,
            callingCode: newCallingCode,
            number: '',
        });
    };

    const handleChangeNumber: ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        const formatter = new AsYouType(countryCode);
        formatter.input(target.value);

        const formatterNumber = formatter.getNumberValue() || '';
        const lengthStatus = validatePhoneNumberLength(formatterNumber, countryCode);

        if (lengthStatus !== 'TOO_LONG') {
            onChange({
                countryCode,
                callingCode: getCountryCallingCode(countryCode),
                number: formatter.getNationalNumber(),
            });
        }
    };

    return (
        <Stack>
            <InputLabel shrink>{label}</InputLabel>

            <Stack direction="row" gap="5px" alignItems="flex-start">
                <Select
                    value={countryCode || ''}
                    onChange={handleChangeCountry}
                    variant="standard"
                    error={error}
                    css={{
                        '.MuiSelect-select': {
                            display: 'flex',
                            alignItems: 'center',
                            '.phone-input__country-name': { display: 'none' },
                        },
                    }}
                >
                    {PHONE_COUNTRIES_MAP.map((code) => (
                        <MenuItem key={code} value={code}>
                            {code && <FlagIcon code={code} />}
                            <Typography className="phone-input__country-name" marginLeft="1em">
                                {regionNames.of(code)}
                            </Typography>
                        </MenuItem>
                    ))}
                </Select>

                <TextField
                    type="tel"
                    required={required}
                    value={inputValue}
                    onChange={handleChangeNumber}
                    error={error}
                    helperText={helperText}
                    variant="standard"
                    fullWidth
                    css={{
                        '.MuiFormHelperText-root': {
                            color: 'red',
                        },
                        input: {
                            padding: '10px 0',
                        },
                        flexGrow: 1,
                    }}
                />
            </Stack>
        </Stack>
    );
};
