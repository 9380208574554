import { ConciergeSuccessResponse, conciergeClient } from 'src/api/concierge';

import { RebookingEndpoints } from './constants';
import {
    RebookingFilterOptions,
    RebookingFilterQuery,
    RebookingPhaseInfo,
    RebookingPhaseQuery,
    RebookingRooms,
    RebookingRoomsMeta,
    RoomPriceInfo,
} from './types';

export const getRebookingRooms = async (params: RebookingFilterQuery) => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<RebookingRooms, RebookingRoomsMeta>
    >(RebookingEndpoints.GetRebookers, { params });

    return response;
};

export const getRebookingFilterTree = async () => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<RebookingFilterOptions>
    >(RebookingEndpoints.GetRebookersFilterTree);

    return response.data.filter_tree;
};

export const getRebookingPhase = async (params: RebookingPhaseQuery) => {
    const { data: response } = await conciergeClient.get<
        ConciergeSuccessResponse<RebookingPhaseInfo>
    >(RebookingEndpoints.GetRebookersPhase, { params });

    return response.data;
};

export const getRoomPrice = async (roomCode: string) => {
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<RoomPriceInfo>>(
        RebookingEndpoints.GetRoomPrice.replace('{room_code}', roomCode)
    );

    return response.data;
};

export const getSwapItDiscount = async (buildingId: string) => {
    const url = RebookingEndpoints.GetSwapItDiscount.replace('{buildingId}', buildingId);
    const { data: response } = await conciergeClient.get<ConciergeSuccessResponse<number>>(url);

    return response.data;
};
