import { useTheme } from '@emotion/react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { Box, Stack } from '@mui/system';
import { FC, Fragment, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';

import { ASSETS_BASE_URL } from 'src/api/concierge';
import { ReactComponent as ArrowIcon } from 'src/assets/svg/control-arrow.svg';
import { ReactComponent as SaveYourFriendsASpotIcon } from 'src/assets/svg/save-your-friends-a-spot.svg';
import { ReactComponent as SaveYourSpotIcon } from 'src/assets/svg/save-your-spot.svg';
import { ReactComponent as SwapYourSpotIcon } from 'src/assets/svg/swap-your-spot.svg';
import { Image } from 'src/components/image';
import { routes } from 'src/constants/routes';
import { useGetActiveBooking } from 'src/features/bookings';
import { formatAmount } from 'src/utils/format-amount';

import { RebookStatus, RebookingPhase } from '../../api/constants';
import { useGetRebookingPhase } from '../../hooks/use-get-rebooking-room-phase';
import { useGetRoomPrice } from '../../hooks/use-get-room-price';
import { createTransactionsLink } from '../../utils/create-transactions-link';

import { RebookingLink } from './rebooking-link';
import { RebookingLinkSkeleton } from './rebooking-link-skeleton';
import { RebookingLinksWrap } from './rebooking-links-wrap';

export const RebookingLinks: FC = () => {
    const { t } = useTranslation();

    const { data: booking } = useGetActiveBooking();
    const { data: phasesInfo, isLoading, isError } = useGetRebookingPhase();
    const theme = useTheme();
    const [roomCode, setRoomCode] = useState<string>('');
    const [keepItStartDate, setKeepItStartDate] = useState<string>('');
    const [keepItActive, setKeepItActive] = useState<boolean>(false);
    const [swapItStartDate, setSwapItStartDate] = useState<string>('');
    const [swapItActive, setSwapItActive] = useState<boolean>(false);
    const [keepItPhaseWeeksFree, setKeepItPhaseWeeksFree] = useState(0);
    const [swapItPhaseWeeksFree, setSwapItPhaseWeeksFree] = useState(0);

    const isSmallScreen = useMediaQuery(theme.breakpoints.down('lg'));
    const { data: roomPrice } = useGetRoomPrice(roomCode);

    const phases = phasesInfo?.phases;
    const rebookStatus = phasesInfo?.rebook_status;

    useEffect(() => {
        if (booking && booking.room) {
            setRoomCode(booking.room);
        }
    }, [booking]);

    useEffect(() => {
        if (phases) {
            const keepItPhase = phases.find((phase) => phase.phase === RebookingPhase.Keep);
            const swapItPhase = phases.find((phase) => phase.phase === RebookingPhase.Swap);

            setKeepItPhaseWeeksFree(keepItPhase ? keepItPhase.discount : 0);
            setSwapItPhaseWeeksFree(swapItPhase ? swapItPhase.discount : 0);

            if (keepItPhase) {
                setKeepItStartDate(keepItPhase.start_date);
            }

            if (swapItPhase) {
                setSwapItStartDate(swapItPhase.start_date);
            }

            if (phasesInfo.is_available && keepItPhase?.active) {
                setKeepItActive(true);
            }

            if (swapItPhase?.active) {
                setSwapItActive(true);

                if (phasesInfo.is_available) {
                    setKeepItActive(true);
                }
            }
        }
    }, [phases, phasesInfo]);

    if (isLoading) {
        return (
            <RebookingLinksWrap>
                <RebookingLinkSkeleton />
                <RebookingLinkSkeleton />
            </RebookingLinksWrap>
        );
    }

    if (isError || !phasesInfo.is_available || rebookStatus === RebookStatus.NotAllowed) {
        return <Navigate to={routes.home} />;
    }

    const formatDate = (date: string) => {
        const dateObj = new Date(date);

        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear().toString().slice(2);

        return `${day}_${month}_${year}`;
    };

    const keepItButtonData = {
        link: keepItActive
            ? createTransactionsLink({
                  room_code: booking!.room,
              })
            : 'https://www.vitastudent.com/en/rebookers',
        content: keepItActive
            ? t('rebookers-card_rebook_now_cta')
            : t('rebookers-card_keep_it_coming_soon_cta'),
    };

    const swapItButtonData = {
        link: swapItActive ? routes.rebooking.swap : 'https://www.vitastudent.com/en/rebookers',
        content: swapItActive
            ? t('rebookers-card_rebook_now_cta')
            : t('rebookers-card_keep_it_coming_soon_cta'),
    };

    const jsx = [
        <RebookingLink
            key="swap"
            title={t('rebookers_swap-it-card_title')}
            subtitle={t('rebookers-card_swap_it_coming_soon_availability', {
                date: formatDate(swapItStartDate),
            })}
            body={
                <Typography>
                    {t('rebookers-card_swap_it_coming_soon_description')}{' '}
                    {swapItPhaseWeeksFree > 0 ? (
                        <Trans
                            i18nKey="rebookers-card_swap_it_coming_soon_description_discount"
                            values={{ count: swapItPhaseWeeksFree }}
                        />
                    ) : null}
                </Typography>
            }
            link={swapItButtonData.link}
            label={
                <Box
                    css={{
                        position: 'absolute',
                        [theme.breakpoints.up('lg')]: {
                            top: 0,
                        },
                        bottom: 0,
                        zIndex: 1,
                    }}
                    textAlign={{ xs: 'right', lg: 'center' }}
                    width="100%"
                    paddingTop="19px"
                >
                    {!isSmallScreen && swapItPhaseWeeksFree > 0 ? (
                        <Typography paddingRight="19px" variant="overlineMedium" fontWeight={700}>
                            {t('rebookers-card_swap_it_coming_soon_up_to')}{' '}
                            <Typography
                                variant="overlineMedium"
                                fontWeight={700}
                                fontSize="24px"
                                textTransform="uppercase"
                                paddingBottom="14px"
                            >
                                <Trans
                                    i18nKey="rebookers-card_swap_it_coming_soon_weeks_free"
                                    values={{ count: swapItPhaseWeeksFree }}
                                />
                            </Typography>
                        </Typography>
                    ) : null}
                    <Box px="19px" pb="30px" display={{ xs: 'inherit', lg: 'none' }}>
                        <Button
                            variant="contained"
                            css={{
                                backgroundColor: 'white',
                                color: 'black',
                                borderRadius: '0px',
                                borderColor: 'black',
                                paddingTop: '16px',
                                paddingBottom: '16px',
                                marginBottom: '16px',
                                border: '1px solid black',
                            }}
                            fullWidth
                            disableElevation
                            href="https://www.vitastudent.com/en/rebookers"
                            target="_blank"
                        >
                            <Typography fontWeight={400} fontSize="22px">
                                {swapItButtonData.content}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            }
            image_srcset={{
                lg: `${ASSETS_BASE_URL}/images/swap-it-desktop.png`,
                xs: `${ASSETS_BASE_URL}/images/swap-it-mobile.png`,
            }}
            disabled={false}
            ga_name="Swap Room Option Card Click"
            cardColor="#71C1A9"
            cta={swapItButtonData.content}
            titleIcon={<SwapYourSpotIcon />}
            caption={
                swapItPhaseWeeksFree > 0 ? (
                    <Typography variant="overlineRegular">
                        {t('rebookers-card_swap_it_coming_soon_up_to')}{' '}
                        <Typography
                            variant="overlineMedium"
                            fontWeight={700}
                            fontSize="24px"
                            textTransform="uppercase"
                        >
                            <Trans
                                i18nKey="rebookers-card_swap_it_coming_soon_weeks_free"
                                values={{ count: swapItPhaseWeeksFree }}
                            />
                        </Typography>
                    </Typography>
                ) : null
            }
        />,
        <RebookingLink
            key="refer"
            title="Save your Friends a spot."
            subtitle={t('rebookers-card_refer_friend_coming_soon_availability')}
            body={t('rebookers-card_refer_friend_coming_soon_description')}
            cta={t('rebookers-card_refer_friend_coming_soon_cta')}
            link="https://vitastudent.typeform.com/to/LtgXvBHz?typeform-source=my-account"
            label={
                <Box
                    css={{
                        position: 'absolute',
                        [theme.breakpoints.up('lg')]: {
                            top: 0,
                        },
                        bottom: 0,
                        zIndex: 1,
                    }}
                    textAlign="center"
                    width="100%"
                    paddingTop="19px"
                >
                    {!isSmallScreen && (
                        <Typography variant="h2" paddingBottom="14px">
                            {t('rebookers-card_refer_friend_coming_soon_weeks_reward')}
                        </Typography>
                    )}
                    <Box px="19px" pb="30px" display={{ xs: 'inherit', lg: 'none' }}>
                        <Button
                            variant="contained"
                            css={{
                                backgroundColor: 'white',
                                color: 'black',
                                borderRadius: '0px',
                                borderColor: 'black',
                                paddingTop: '16px',
                                paddingBottom: '16px',
                                marginBottom: '16px',
                                border: '1px solid black',
                            }}
                            fullWidth
                            disableElevation
                            href="https://vitastudent.typeform.com/to/LtgXvBHz?typeform-source=my-account"
                            target="_blank"
                        >
                            <Typography fontWeight={400} fontSize="22px">
                                {t('rebookers-card_refer_friend_coming_soon_cta')}
                            </Typography>
                        </Button>
                    </Box>
                </Box>
            }
            image_srcset={{
                lg: `${ASSETS_BASE_URL}/images/refer-it-desktop.png`,
                xs: `${ASSETS_BASE_URL}/images/refer-it-mobile.png`,
            }}
            disabled={false}
            ga_name="Refer Card Option Card Click"
            cardColor="#EC619F"
            titleIcon={<SaveYourFriendsASpotIcon />}
            caption={null}
        />,
    ];

    const RebookInfo = (
        <Fragment>
            <Box
                css={{
                    display: 'block',
                    textDecoration: 'none',
                    color: 'inherit',
                    marginBottom: '16px',
                }}
                border="1px solid black"
                display={{ xs: 'none', lg: 'inherit' }}
            >
                <Stack direction="row-reverse">
                    <Box position="relative" flex={1} display={{ xs: 'none', lg: 'inherit' }}>
                        <Image
                            srcSet={{
                                xl: `${ASSETS_BASE_URL}/images/rebooker_main_banner_1920.jpeg`,
                                lg: `${ASSETS_BASE_URL}/images/rebooker_main_banner_1366.jpeg`,
                                md: `${ASSETS_BASE_URL}/images/rebooker_main_banner_768.jpeg`,
                                xs: `${ASSETS_BASE_URL}/images/rebooker_main_banner_375.jpeg`,
                            }}
                            alt="Rebookers banner"
                        />

                        <Typography
                            position="absolute"
                            top="24px"
                            left="23px"
                            variant="h2"
                            textTransform="uppercase"
                            display={{ xs: 'inherit', lg: 'none' }}
                        >
                            {t('rebookers-card_how_it_works_title')}
                        </Typography>
                    </Box>

                    <Box flex={1} position="relative">
                        <Stack
                            rowGap="31px"
                            marginLeft={{ xs: '30px', lg: '40px' }}
                            marginTop={{ xs: '40px', lg: '40px' }}
                            marginBottom={{ xs: '68px', lg: '68px' }}
                            marginRight={{ xs: '73px', lg: '73px' }}
                        >
                            <Stack direction="row" justifyContent="space-between">
                                <Box width="fit-content" textAlign="right">
                                    <Typography
                                        variant="h1"
                                        color="black"
                                        textTransform="uppercase"
                                    >
                                        {t('rebookers-card_coming-soon_title')}
                                    </Typography>
                                    <Typography
                                        variant="h3"
                                        color="black"
                                        textAlign="right"
                                        width="100%"
                                    >
                                        {t('rebookers-card_coming-soon_years')}
                                    </Typography>
                                </Box>
                                <Box
                                    textAlign="right"
                                    display="flex"
                                    flexDirection="column"
                                    justifyContent="flex-end"
                                >
                                    <Typography
                                        variant="h2"
                                        color="black"
                                        textTransform="uppercase"
                                    >
                                        {t('rebookers-card_how_it_works_title')}
                                    </Typography>
                                </Box>
                            </Stack>
                            <Typography variant="overlineRegular" color="black" fontSize="18px">
                                {t('rebookers-card_how_it_works_description')}
                            </Typography>
                            <br />
                            <Button variant="text" href="https://www.vitastudent.com/en/rebookers">
                                <Typography
                                    color={theme.palette.primary.main}
                                    css={{ textDecoration: 'underline' }}
                                >
                                    {t('rebookers-card_how_it_works_cta')}
                                </Typography>
                            </Button>
                        </Stack>
                    </Box>
                </Stack>
            </Box>
            <Box display={{ xs: 'inherit', lg: 'none' }}>
                <Accordion elevation={1}>
                    <AccordionSummary
                        css={{
                            backgroundColor: 'white',
                            paddingTop: '20px',
                            paddingBottom: '20px',
                        }}
                        expandIcon={<ArrowIcon color="black" />}
                    >
                        <Typography variant="h2" textTransform="uppercase">
                            {t('rebookers-card_how_it_works_title')}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails
                        css={{
                            backgroundColor: 'white',
                            paddingLeft: '20px',
                            paddingRight: '20px',
                        }}
                    >
                        <Box width="fit-content" textAlign="right" pb="26px">
                            <Typography variant="h1" color="black" textTransform="uppercase">
                                {t('rebookers-card_how_it_works_caption')}
                            </Typography>
                            <Typography variant="h3" color="black" textAlign="right" width="100%">
                                {t('rebookers-card_how_it_works_years')}
                            </Typography>
                        </Box>
                        <Typography variant="overlineMedium" fontSize="16px">
                            {t('rebookers-card_how_it_works_description')}
                        </Typography>
                        <br />
                        <br />
                        <Button
                            variant="text"
                            href="https://www.vitastudent.com/en/rebookers"
                            css={{ paddingLeft: 0 }}
                        >
                            <Typography
                                color={theme.palette.primary.main}
                                css={{ textDecoration: 'underline' }}
                            >
                                {t('rebookers-card_how_it_works_cta')}
                            </Typography>
                        </Button>
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Fragment>
    );
    const SaveYourSpotInfo = (
        <Box
            css={{
                display: 'block',
                textDecoration: 'none',
                color: 'inherit',
                marginBottom: '16px',
            }}
        >
            <Stack direction={{ xs: 'column-reverse', lg: 'row' }}>
                <Box position="relative" flex={1}>
                    <Image
                        srcSet={{
                            lg: `${ASSETS_BASE_URL}/images/keep-it-desktop.png`,
                            md: `${ASSETS_BASE_URL}/images/keep-it-mobile.png`,
                            xs: `${ASSETS_BASE_URL}/images/keep-it-mobile.png`,
                        }}
                        css={{
                            minHeight: '268px',
                            [theme.breakpoints.up('lg')]: {
                                minHeight: '459px',
                            },
                        }}
                        alt="Rebookers banner"
                    />

                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        textTransform="uppercase"
                        flexDirection="column"
                        justifyContent={{ xs: 'flex-end', lg: 'flex-start' }}
                        alignItems="flex-start"
                        height="100%"
                        width="100%"
                        px="20px"
                        py="30px"
                        display="flex"
                    >
                        {isSmallScreen ? (
                            <Button
                                variant="contained"
                                css={{
                                    backgroundColor: 'white',
                                    color: 'black',
                                    borderRadius: '0px',
                                    borderColor: 'black',
                                    paddingTop: '16px',
                                    paddingBottom: '16px',
                                    border: '1px solid black',
                                }}
                                fullWidth
                                disableElevation
                                href={keepItButtonData.link}
                                target="_blank"
                            >
                                <Typography fontWeight={400} fontSize="22px">
                                    {keepItButtonData.content}
                                </Typography>
                            </Button>
                        ) : (
                            <Box textAlign="center">
                                <SaveYourSpotIcon style={{ color: '#F6E946' }} />
                            </Box>
                        )}
                    </Box>
                </Box>

                <Box flex={1} css={{ background: '#F6E946' }} position="relative">
                    <Box
                        paddingTop="19px"
                        paddingBottom="6px"
                        borderBottom="1px solid black"
                        marginX="30px"
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Typography variant="h6" fontWeight={700} fontSize="28px">
                            {t('rebookers-card_keep_it_start_date', {
                                date: formatDate(keepItStartDate),
                            })}
                        </Typography>
                        {!isSmallScreen && keepItPhaseWeeksFree > 0 ? (
                            <Typography
                                variant="overlineRegular"
                                display="flex"
                                flexDirection="row"
                                alignItems="flex-end"
                            >
                                {t('rebookers-card_keep_it_coming_soon_up_to')}&nbsp;
                                <Typography variant="h6" fontWeight={700} fontSize="28px">
                                    <Trans
                                        i18nKey="rebookers-card_keep_it_coming_soon_weeks_free"
                                        values={{ count: keepItPhaseWeeksFree }}
                                    />
                                </Typography>
                            </Typography>
                        ) : null}
                    </Box>
                    <Stack
                        rowGap={{ xs: '15px', lg: '31px' }}
                        marginLeft={{ xs: '30px', lg: '15px' }}
                        marginTop={{ xs: '10px', lg: '15px' }}
                        marginBottom={{ xs: '10px', lg: '15px' }}
                        marginRight={{ xs: '30px', lg: '15px' }}
                        alignItems="center"
                    >
                        {isSmallScreen && (
                            <Box
                                justifyContent="space-between"
                                alignItems="center"
                                display="flex"
                                flexDirection="row"
                                width="100%"
                            >
                                <Box textAlign="center">
                                    <SaveYourSpotIcon
                                        style={{
                                            color: '#1D162E',
                                        }}
                                    />
                                </Box>
                                {keepItPhaseWeeksFree > 0 ? (
                                    <Typography variant="overlineRegular" textAlign="right">
                                        {t('rebookers-card_keep_it_coming_soon_up_to')}{' '}
                                        <Typography
                                            variant="overlineMedium"
                                            fontWeight={700}
                                            fontSize="24px"
                                        >
                                            <Trans
                                                i18nKey="rebookers-card_keep_it_coming_soon_weeks_free"
                                                values={{ count: keepItPhaseWeeksFree }}
                                            />
                                        </Typography>
                                    </Typography>
                                ) : null}
                            </Box>
                        )}
                        <Box
                            borderBottom="1px solid black"
                            width={{ xs: '100%', lg: '70%' }}
                            paddingBottom={{ xs: '10px', lg: '20px' }}
                            marginBottom={{ xs: '10px', lg: '20px' }}
                        >
                            <Stack
                                spacing={3}
                                justifyContent="center"
                                alignItems={{ xs: 'flexStart', lg: 'center' }}
                                display="flex"
                                flexDirection="column"
                            >
                                {keepItActive && !swapItActive && (
                                    <Typography
                                        variant="overlineMedium"
                                        color="black"
                                        fontSize={{ xs: '16px', lg: '18px' }}
                                        textAlign={{ xs: 'left', lg: 'center' }}
                                    >
                                        {t('rebookers-card_keep_it_price_release_content_one')}
                                    </Typography>
                                )}
                                {keepItPhaseWeeksFree > 0 ? (
                                    <Typography
                                        variant="overlineMedium"
                                        color="black"
                                        fontSize={{ xs: '16px', lg: '18px' }}
                                        textAlign={{ xs: 'left', lg: 'center' }}
                                    >
                                        <Trans
                                            i18nKey="rebookers-card_keep_it_price_release_content_two"
                                            values={{ count: keepItPhaseWeeksFree }}
                                        />
                                    </Typography>
                                ) : null}
                            </Stack>
                        </Box>
                        <Box>
                            <Stack
                                justifyContent="center"
                                alignItems={{ xs: 'flexStart', lg: 'center' }}
                                display="flex"
                                flexDirection="column"
                            >
                                <Typography
                                    variant="overlineMedium"
                                    fontWeight={500}
                                    fontSize={{ xs: '22px', lg: '24px' }}
                                >
                                    {t('rebookers-card_keep_it_price_release_academic_year_price')}
                                </Typography>
                                <Typography variant="h6" fontSize={{ xs: '42px', lg: '64px' }}>
                                    {t('rebookers-card_keep_it_price_release_price', {
                                        price: roomPrice
                                            ? formatAmount(
                                                  roomPrice.room_price.toFixed(2),
                                                  roomPrice.currency
                                              )
                                            : '',
                                        length: roomPrice?.is_monthly ? 'PM' : 'PW',
                                    })}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box
                            justifyContent="center"
                            alignItems="center"
                            display="flex"
                            flexDirection="column"
                            paddingBottom="10px"
                        >
                            {!isSmallScreen && (
                                <Button
                                    variant="contained"
                                    css={{
                                        backgroundColor: 'white',
                                        color: 'black',
                                        borderRadius: '0px',
                                        borderColor: 'black',
                                        paddingTop: '16px',
                                        paddingBottom: '16px',
                                        marginBottom: '16px',
                                        border: '1px solid black',
                                        width: '60%',
                                    }}
                                    disableElevation
                                    href={keepItButtonData.link}
                                    target="_blank"
                                >
                                    <Typography fontWeight={400} fontSize="22px">
                                        {keepItButtonData.content}
                                    </Typography>
                                </Button>
                            )}
                            <Typography fontSize="11px">
                                {t('rebookers-card_keep_it_price_release_ts_and_cs')}
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
            </Stack>
        </Box>
    );

    return (
        <RebookingLinksWrap RebookingInfo={RebookInfo} saveYourSpot={SaveYourSpotInfo}>
            {jsx}
        </RebookingLinksWrap>
    );
};
