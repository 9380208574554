import { parsePhoneNumberFromString } from 'libphonenumber-js';

/**
 * Adds a plus at the beginning of a phone number and returns the country flag code.
 */
export const phoneNumberPlus = (phoneNumber: string) => {
    let validPhoneNumber = '';

    if (typeof phoneNumber === 'string') {
        validPhoneNumber = phoneNumber;
    } else {
        validPhoneNumber = '';
    }
    const formattedPhoneNumber =
        validPhoneNumber.charAt(0) === '+' ? validPhoneNumber : `+${validPhoneNumber}`;

    const phoneNumberParsed = parsePhoneNumberFromString(formattedPhoneNumber);

    return {
        phoneNumber: phoneNumberParsed && phoneNumberParsed.isValid() ? formattedPhoneNumber : '',
        countryCode: phoneNumberParsed ? phoneNumberParsed.country : '',
    };
};
