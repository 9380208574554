import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { getRoomPrice } from '../api/requests';
import { RoomPriceInfo } from '../api/types';

export const useGetRoomPrice = (roomCode: string, options?: UseQueryOptions<RoomPriceInfo>) =>
    useQuery<RoomPriceInfo>(['roomPrice', roomCode], () => getRoomPrice(roomCode), {
        ...options,
        enabled: !!roomCode,
    });
