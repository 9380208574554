import { differenceInHours, parseISO } from 'date-fns';

/**
 * Checks if the given date is within 48 hours of the current date.
 *
 * @param amendRequestDateISO - ISO string of the amend request date.
 * @returns {boolean} - True if within 48 hours, false otherwise.
 */
export const isAmendInDate = (amendRequestDateISO: string | null): boolean => {
    if (!amendRequestDateISO) return false;
    const amendRequestDate = parseISO(amendRequestDateISO);
    const currentDate = new Date();
    return differenceInHours(currentDate, amendRequestDate) <= 48;
};
