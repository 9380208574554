import { Box, Stack } from '@mui/material';
import { FC, ReactNode } from 'react';

export const RebookingLinksWrap: FC<{
    children: ReactNode;
    RebookingInfo?: ReactNode;
    saveYourSpot?: ReactNode;
}> = ({ children, RebookingInfo, saveYourSpot }) => (
    <Stack flex={1} justifyContent="center" alignItems="center">
        {RebookingInfo && (
            <Box
                display="grid"
                width="100%"
                gap={{ xs: '12px', md: '24px' }}
                gridTemplateColumns={{
                    xs: '1fr',
                }}
                padding={{
                    xs: '24px 16px 32px 16px',
                    md: '0 30px',
                    lg: '0 60px',
                }}
            >
                {RebookingInfo}
                {saveYourSpot}
            </Box>
        )}
        <Box
            display="grid"
            width="100%"
            gap={{ xs: '12px', md: '24px' }}
            gridTemplateColumns={{
                xs: '1fr',
                lg: '1fr 1fr',
            }}
            padding={{
                xs: '24px 16px 32px 16px',
                md: '0 30px',
                lg: '0 60px',
            }}
        >
            {children}
        </Box>
    </Stack>
);
