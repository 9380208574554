import { useTheme } from '@emotion/react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Image, SrcSet } from 'src/components/image';
import { GA4 } from 'src/lib/ga4';
import { isRelativeUrl } from 'src/utils/is-link-relative';

interface Props {
    title: string;
    subtitle: string;
    body: ReactNode;
    link: string;
    label: ReactNode;
    image_srcset: SrcSet;
    disabled: boolean;
    ga_name: string;
    cardColor: string;
    cta: string;
    titleIcon: ReactNode;
    caption: ReactNode;
}

export const RebookingLink: FC<Props> = ({
    title,
    subtitle,
    body,
    link,
    label,
    image_srcset,
    disabled,
    ga_name,
    cardColor,
    cta,
    titleIcon,
    caption,
}) => {
    const { palette, shadows, breakpoints } = useTheme();

    const linkProps =
        !disabled &&
        (isRelativeUrl(link)
            ? {
                  component: Link,
                  to: link,
              }
            : {
                  component: 'a',
                  target: '_blank',
                  href: link,
              });

    const handleClick = () =>
        GA4.ctaEvent({
            name: ga_name,
            location: 'Rebook Option Cards',
            label: title,
        });

    return (
        <Stack
            {...linkProps}
            boxShadow={shadows[disabled ? 0 : 1]}
            flex={{ lg: 1 }}
            css={{
                textDecoration: 'none',
                color: 'inherit',
                background: disabled ? palette.background.paper : palette.background.default,
            }}
            onClick={handleClick}
            direction={{ xs: 'column-reverse', lg: 'row' }}
        >
            <Box position="relative" flex={1}>
                <Image
                    srcSet={image_srcset}
                    alt="banner"
                    css={{
                        minHeight: '268px',
                        [breakpoints.up('lg')]: {
                            minHeight: '527px',
                        },
                    }}
                />

                {label}
            </Box>

            <Stack
                paddingTop="19px"
                flex={1}
                bgcolor={cardColor}
                justifyContent="space-between"
                pb="28px"
                pt="20px"
            >
                <Typography
                    px="8px"
                    component="p"
                    variant="h6"
                    color="black"
                    marginBottom="8px"
                    textTransform="uppercase"
                    mb="18px"
                    pb="7px"
                    mx="22px"
                    textAlign="left"
                    css={{
                        [breakpoints.up('lg')]: {
                            borderBottom: 'none',
                            textAlign: 'center',
                        },
                        borderBottom: '1px solid black',
                        textAlign: 'left',
                    }}
                >
                    {subtitle}
                </Typography>
                <Stack
                    direction="row"
                    justifyContent={{ xs: 'space-between', lg: 'center' }}
                    px="30px"
                    columnGap="20px"
                >
                    <Box marginTop="33px" marginBottom="18px" textAlign="center">
                        {titleIcon}
                    </Box>
                    <Box
                        display={{ xs: 'flex', lg: 'none' }}
                        alignItems="flex-start"
                        pb="18px"
                        pt="45px"
                        textAlign="right"
                    >
                        {caption}
                    </Box>
                </Stack>
                <Typography variant="body2" px="30px">
                    {body}
                </Typography>
                <Box
                    display={{ xs: 'none', lg: 'flex' }}
                    justifyContent="center"
                    alignItems="center"
                    marginTop="40px"
                    px="30px"
                >
                    <Button
                        variant="contained"
                        fullWidth
                        css={{
                            backgroundColor: 'white',
                            color: 'black',
                            borderRadius: '0px',
                            borderColor: 'black',
                            paddingTop: '16px',
                            paddingBottom: '16px',
                            marginBottom: '16px',
                            border: '1px solid black',
                        }}
                        disableElevation
                        href={link}
                        target="_blank"
                    >
                        <Typography fontWeight={400} fontSize="22px">
                            {cta}
                        </Typography>
                    </Button>
                </Box>
            </Stack>
        </Stack>
    );
};
