import { appConfig } from 'src/app/app-config';

interface LinkParams {
    room_code: string;
}

const { academicYear, transactionsURL } = appConfig.rebooking;

export const createTransactionsLink = ({ room_code }: LinkParams): string =>
    transactionsURL.replace('{room_code}', room_code).replace('{academic_year}', academicYear);
