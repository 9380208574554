export enum RebookingEndpoints {
    GetRebookers = '/v1/rebook/rooms',
    GetRebookersFilterTree = '/v1/rebook/rooms/filter-tree',
    GetRebookersPhase = '/v1/rebook/phase',
    GetRoomPrice = '/v1/rebook/price?room_code={room_code}',
    GetSwapItDiscount = '/v1/rebook/{buildingId}/swap-discount',
}

export enum RebookingPhase {
    ComingSoon = 'coming_soon',
    Keep = 'keep_your_room',
    Swap = 'swap_your_room',
}

export enum RebookingFilterParams {
    City = 'cities',
    BuildingId = 'building_id',
    RoomCode = 'room_code',
    RoomType = 'room_type',
    RoomFloor = 'room_floor',
    Page = 'page',
    CurrentDate = 'current_date',
}

export enum RebookStatus {
    NotAllowed = 'not_allowed',
    ComingSoon = 'coming_soon',
    Active = 'active',
    Ended = 'ended',
}
