import { useTheme } from '@emotion/react';
import { Alert, Box, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from 'src/components/button';
import { Image } from 'src/components/image';
import { formatAmount } from 'src/utils/format-amount';

import { RebookingRoom } from '../../api/types';
import { createTransactionsLink } from '../../utils/create-transactions-link';

import { RebookingDiscountTooltip } from './rebooking-discount-tooltip';

interface InfoFieldProps {
    label: ReactNode;
    value: ReactNode;
}

const InfoField: FC<InfoFieldProps> = ({ label, value }) => (
    <Box>
        <Typography variant="body2" color="text.secondary" gutterBottom>
            {label}
        </Typography>
        <Typography variant="overlineMedium">{value}</Typography>
    </Box>
);

interface RebookingCardProps {
    room: RebookingRoom;
    weeksFree: number;
}

export const RebookingCard: FC<RebookingCardProps> = ({ room, weeksFree = 0 }) => {
    const { palette } = useTheme();
    const { t } = useTranslation();

    const showTooltip = room.discount !== 0;

    const transactionURL = createTransactionsLink({ room_code: room.room_code });

    return (
        <Stack direction={{ lg: 'row' }} css={{ background: palette.background.paper }}>
            <Box position="relative" width={{ lg: '301px' }} height={{ xs: '280px', lg: 'auto' }}>
                {weeksFree ? (
                    <Box
                        sx={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }}
                        padding="20px"
                        textAlign="center"
                        bgcolor="#F6E946"
                        textTransform="uppercase"
                    >
                        <Typography variant="h2">
                            {weeksFree > 1 ? (
                                <Trans
                                    i18nKey="rebookers_swap_your_room_weeks_free_plural"
                                    values={{ weeks: weeksFree }}
                                />
                            ) : (
                                t('rebookers_swap_your_room_weeks_free')
                            )}
                        </Typography>
                    </Box>
                ) : null}
                <Image
                    src={room.image_url}
                    srcSet={{
                        xl: `${room.image_url}?width=300`,
                        lg: `${room.image_url}?width=1200`,
                        md: `${room.image_url}?width=725`,
                        sm: `${room.image_url}?width=300`,
                        xs: `${room.image_url}?width=300`,
                    }}
                    alt={room.title}
                />

                {room.discount ? (
                    <Alert
                        variant="filled"
                        severity="info"
                        icon={false}
                        css={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                        }}
                    >
                        {t('rebookers_swap_your_room_discount', { count: room.discount })}
                    </Alert>
                ) : null}
            </Box>

            <Box flex={1} padding={{ xs: '32px 24px', md: '32px 40px', lg: '32px' }}>
                <Typography
                    variant="h3"
                    width="fit-content"
                    paddingBottom="8px"
                    marginBottom="16px"
                    borderBottom="1px solid"
                    textTransform="uppercase"
                >
                    {room.title}
                </Typography>

                <Box
                    display="grid"
                    gap="12px"
                    gridTemplateColumns={{
                        xs: '1fr 1fr',
                        md: '1fr 1fr 1fr 1fr',
                        lg: '1fr 1fr',
                        xl: '1fr 1fr 1fr 1fr',
                    }}
                    paddingBottom="16px"
                    marginBottom="30px"
                    css={{ borderBottom: `1px solid ${palette.text.secondary}` }}
                >
                    <InfoField
                        label={t('rebookers_swap_your_room_code_label')}
                        value={room.room_code}
                    />
                    <InfoField
                        label={t('rebookers_swap_your_room_floor_label')}
                        value={room.room_floor}
                    />
                    <InfoField
                        label={t('rebookers_swap_your_room_year_label')}
                        value={room.academic_year}
                    />
                    <InfoField label={t('rebookers_swap_your_room_size_label')} value={room.size} />
                </Box>

                <Box
                    display="grid"
                    justifyContent={{ lg: 'space-between' }}
                    alignItems="center"
                    gap={{ xs: '28px', md: '28px', lg: 0 }}
                    gridTemplateAreas={{
                        xs: '"price" "tooltip" "cta"',
                        md: '"tooltip tooltip tooltip tooltip" "price price price cta"',
                        lg: '"tooltip cta" "price cta"',
                    }}
                >
                    <Stack gridArea="price">
                        {showTooltip && (
                            <Typography
                                color="text.secondary"
                                css={{ textDecoration: 'line-through' }}
                            >
                                <Trans
                                    i18nKey={
                                        room.is_monthly
                                            ? 'rebookers_swap_your_room_monthly_price'
                                            : 'rebookers_swap_your_room_weekly_price'
                                    }
                                    values={{
                                        price: formatAmount(room.original_price, room.currency),
                                    }}
                                />
                            </Typography>
                        )}

                        <Typography variant="h2" marginTop="8px">
                            <Trans
                                i18nKey={
                                    room.is_monthly
                                        ? 'rebookers_swap_your_room_monthly_price'
                                        : 'rebookers_swap_your_room_weekly_price'
                                }
                                values={{
                                    price: formatAmount(room.calculated_price, room.currency),
                                }}
                            />
                        </Typography>
                        {weeksFree ? (
                            <Typography
                                component="p"
                                variant="h6"
                                css={{
                                    textTransform: 'uppercase',
                                    fontSize: '13px',
                                    marginTop: '14px',
                                }}
                            >
                                <Trans
                                    i18nKey="swap-your-room_room-card_discount-notice_subtext"
                                    values={{ count: weeksFree }}
                                />
                            </Typography>
                        ) : null}
                    </Stack>

                    <RebookingDiscountTooltip />

                    <Button
                        href={transactionURL}
                        target="_blank"
                        design="primary"
                        css={{ padding: '16px 33px', gridArea: 'cta' }}
                    >
                        {t('rebookers_swap_your_room_book_room_cta')}
                    </Button>
                </Box>
            </Box>
        </Stack>
    );
};
