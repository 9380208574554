import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { ConciergeErrorResponse } from 'src/api/concierge';

import { RebookingEndpoints } from '../api/constants';
import { getSwapItDiscount } from '../api/requests';
import { useRebookingStore } from '../store';

export const useGetSwapItDiscount = () => {
    const { filters } = useRebookingStore();

    let buildingId = '';

    if (filters && filters.building_id) {
        buildingId = filters.building_id.building_id ?? '';
    }

    return useQuery<number, AxiosError<ConciergeErrorResponse>>({
        enabled: !!buildingId,
        queryKey: [RebookingEndpoints.GetSwapItDiscount.replace('{buildingId}', buildingId)],
        queryFn: () => getSwapItDiscount(buildingId),
    });
};
